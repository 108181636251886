import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getSelectedProductIdFromActionInput, getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';

/**
 * GetDynamicProductDetails Input Action
 */
export class GetDynamicProductDetailsInput implements IActionInput {
    public productId: number;

    public channelId: number;

    public catalogId: number;

    public selectedProduct: SimpleProduct | undefined;
    // TODO: Construct the input needed to run the action
    constructor(productId: number, channelId: number, selectedProduct?: SimpleProduct, catalogId?: number) {
        this.productId = productId;
        this.channelId = channelId;
        this.selectedProduct = selectedProduct;
        this.catalogId = catalogId || 0;
    }

    // TODO: Determine if the results of this get action should cache the results and if so provide
    // a cache object type and an appropriate cache key
    public getCacheKey = () => 'DynamicProductDetails';

    public getCacheObjectType = () => 'SimpleProductValue';

    public dataCacheType = (): CacheType => 'none';
}

// TODO: Create a data model here or import one to capture the response of the action
export interface IGetDynamicProductDetailsData {
    text: string;
}

/**
 * TODO: Use this function to create the input required to make the action call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetDynamicProductDetailsInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);
    if (productId) {
        console.log('Product Id has been found', productId);
        return new GetDynamicProductDetailsInput(+productId, +inputData.requestContext.apiSettings.channelId);
    }
    throw new Error('Unable to create PriceForSelectedVariantInput, no productId found on module config or query');
};

/**
 * TODO: Use this function to call your action and process the results as needed
 */
async function action(input: GetDynamicProductDetailsInput, ctx: IActionContext): Promise<SimpleProduct | null> {
    // const apiSettings = Msdyn365.msdyn365Commerce.apiSettings;

    // TODO: Uncomment the below line to get the value from a service
    // const response = await Msdyn365.sendRequest<IGetDynamicProductDetailsData[]>('/get/example/id/1', 'get');
    return Promise.resolve()
        .then(() => {
            const activeProduct: SimpleProduct | undefined = input.selectedProduct;
            if (!activeProduct) {
                const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);
                console.log('Got the Simple Product Object');
                return getSelectedVariant(selectedVariantInput, ctx);
            }
            return activeProduct;
        })
        .catch((error: Error) => {
            ctx.trace(error.message);
            throw new Error('[getPriceForSelectedVariantAction]Error executing action');
        });
}

export default createObservableDataAction({
    action: <IAction<SimpleProduct>>action,
    // TODO: Give your data action a unique id
    id: 'GetDynamicProductDetails',
    input: createInput
    // TODO: Uncomment the below line if this is a meant to be a batched data action
    // isBatched: true
});
